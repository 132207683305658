import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import '../styles/all.scss'
import '../styles/preview.css'

// Components
import Post from '../templates/post'

const PreviewTemplate = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)
  const postId = urlParams.get('post_id')

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  const [revision, setRevision] = React.useState(null)
  const [status, setStatus] = React.useState('fetching')

  const templates = {
    post: { component: Post },
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `${site?.siteMetadata?.siteWpUrl}/wp-json/preview/v1/revision?post_id=${postId}&convert={}`,
          { method: 'GET' }
        )

        const data = await response.json()

        setRevision(data)
        setStatus('done')
      } catch (error) {
        setStatus('error')
      }
    })()
  }, [])

  if (status === 'fetching') {
    return <Message content="Voorbeeld aan het laden ..." />
  }

  if (status === 'error') {
    return <Message content="Er ging iets fout" />
  }

  if (!revision || !revision.acf) {
    return (
      <Message
        content="Geen revisie(s) gevonden"
        subContent={`Ondersteunt berichttype "${revision?.post_type}" revisies?`}
      />
    )
  }

  const template = revision.template
    ? revision.template.replace('.php', '')
    : revision.post_type

  if (!templates[template]) {
    return (
      <Message
        content={`Geen sjabloon gevonden voor berichttype "${revision?.post_type}"`}
        subContent="Koppel het berichttype aan een sjabloon"
      />
    )
  }

  const templateData = {
    data: {
      page: {
        title: revision.post_title,
        path: location.pathname,
        acf: revision.acf,
      },
    },
    location,
  }

  const PageTemplate = templates[template].component

  return (
    <div className="preview">
      <PageTemplate {...templateData} />
    </div>
  )
}

const Message = ({ content, subContent = '' }) => (
  <div className="bg-[#f0f0f1] h-screen w-screen">
    <div className="bg-[#1d2327] w-full h-8" />
    <div className="py-5 d-flex justify-content-center">
      <div>
        <h1 className="font-primary text-2xl color-[#3c434a] text-center">
          {content}
        </h1>
        {subContent && (
          <h2 className="mt-3 font-primary text-sm italic color-[#646970] text-center">
            {subContent}
          </h2>
        )}
      </div>
    </div>
  </div>
)

export default PreviewTemplate
