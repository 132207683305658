/* eslint-disable react/no-array-index-key */
import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'
import CustomLink from 'components/shared/CustomLink'

// Images
import Calendar from 'img/icon/calendar.inline.svg'
import Eyeglasses from 'img/icon/eyeglasses.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const StyledBestBlogs = styled.div`
  @media screen and (max-width: 991px) {
    padding: 0 10px 40px 10px;
  }
`

const InnerBlogs = styled.div`
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 40px 30px 5px 30px;
  background-color: ${props => props.theme.color.face.light};
  max-width: 417px;

  h2 {
    font-size: ${props => props.theme.font.size.xml};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const BlogShadow = styled(motion.div)`
  z-index: -1;
  position: absolute;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.gradient.main};
`

const Post = styled(motion.div)`
  h3 {
    font-size: ${props => props.theme.font.size.ms};
    font-weight: ${props => props.theme.font.weight.xl};
    width: 60%;

    @media screen and (max-width: 991px) {
      width: 80%;
    }
  }

  ${props => props.line && css`
    border-top: 1px solid ${props.theme.color.face.border};
  `}
`

const StyledImg = styled(Img)`
  border-radius: 10px;
  z-index: 1;
`

const ImgContainer = styled.div`
  position: relative;
`

const Description = styled.div`
  min-height: 55px;

  p {
    font-size: ${props => props.theme.font.size.s};
  }
`

const ImgShadow = styled(motion.div)`
  height: 97%;
  width: 97%;
  position: absolute;
  background: ${props => props.theme.color.gradient.main};
  border-radius: 10px;
`

const BestBlogs = ({ className }) => {
  const {
    posts: {
      edges: posts
    }
  } = useStaticQuery(graphql`{
    posts: allWordpressPost(sort: {fields: date}, limit: 3) {
      edges {
        node {
          ...PostsPreviewFrag
        }
      }
    }
  }`)

  const [ref, isVisible] = useInView({
    threshold: 0.5,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledBestBlogs className={`d-flex justify-content-center justify-content-lg-end ${className ? `${className}` : ``}`}>
      <InnerBlogs>
        <h2>Best gelezen blogs</h2>
        {posts.map(({ node: post }, index) => (
          <Post 
            line={index !== 0} 
            key={post.wordpress_id} 
            className="pb-lg-4 pb-2 pt-lg-5 pt-3"
            whileHover="hover"
            initial="rest"
            animate={{}}
          >
            <h3>{post.title}</h3>
            <div className="d-flex pb-2">
              {post.categories.map((category, lowerIndex) => (
                <p key={lowerIndex} className="mb-1 color-secondary mr-3 font-weight-xl font-size-xs">{category.name}</p>
              ))}
            </div>
            <div className="row">
              <div className="col-md-5">
                <CustomLink to={post.path}>
                  <ImgContainer>
                    <StyledImg fluid={post.acf.preview.image.localFile.childImageSharp.fluid} alt="" />
                    <ImgShadow 
                      variants={{rest: { right: 0, bottom: 0 }, hover: { right: -10, bottom: -10 }}}
                    />
                  </ImgContainer>
                </CustomLink>
              </div>
              <div className="col-md-7">
                <div className="d-flex pt-lg-0 pt-3 align-items-center">
                  <p className="mb-0 mr-3 font-size-xs color-soft">
                    <Calendar className="mr-1" />
                    {post.acf.preview.date}
                  </p>
                  <p className="mb-0 font-size-xs color-soft">
                    <Eyeglasses className="mr-1" />
                    {post.acf.preview.readtime}
                  </p>
                </div>
                <div className="pt-2">
                  <Description>
                    {parse(post.acf.preview.small_description)}
                  </Description>
                </div>
                <div className="d-flex pt-2 justify-content-end">
                  <ButtonMinimal to={post.path} icon="arrow">
                    <p>Lees verder</p>
                  </ButtonMinimal>
                </div>
              </div>
            </div>
          </Post>
        ))}
        <BlogShadow
          ref={ref}
          initial={{ right: 0, bottom: 0 }}
          animate={{ right: -40, bottom: -40 }}
          transition={{ delay: 0.3, type: 'spring' }}
        />
      </InnerBlogs>
    </StyledBestBlogs>
  )
}

export default BestBlogs